<template>
  <a-modal
    :destroyOnClose="true"
    :maskClosable="false"
    title="重命名"
    :visible="visibleCompu"
    :okButtonProps="{ loading }"
    :cancelButtonProps="{ disable: loading }"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form ref="formRef" :model="formData">
      <a-form-item
        name="name"
        :rules="{ required: true, message: '请输入文件名', tigger: 'blur' }"
      >
        <a-input
          v-model:value="formData.name"
          placeholder="请输入文件名"
          ref="inputRef"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, ref, onMounted, nextTick } from 'vue'
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: () => ''
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String
    }
  },
  setup(props, { emit }) {
    const valueCompu = computed(() => props.value)

    const lastIndexOf = ref(valueCompu.value.lastIndexOf('.'))

    // 文件类型
    const fileType = ref(
      props.type !== 'speak' &&
        props.type !== 'plan' &&
        props.type !== 'ppt' &&
        props.type !== 0
        ? lastIndexOf.value > 0
          ? valueCompu.value.substring(
            lastIndexOf.value,
            valueCompu.value.length
          )
          : ''
        : ''
    )
    // 文件名
    const fileName = ref(
      props.type !== 'speak' &&
        props.type !== 'plan' &&
        props.type !== 'ppt' &&
        props.type !== 0
        ? valueCompu.value.substring(
          0,
          lastIndexOf.value < 0 ? valueCompu.value.length : lastIndexOf.value
        )
        : valueCompu.value
    )

    const visibleCompu = computed(() => props.visible)

    const formData = ref({ name: fileName.value })
    const formRef = ref()

    const handleCancel = () => {
      emit('onCancel')
    }

    const handleOk = () => {
      formRef.value.validate().then(() => {
        emit('onOk', formData.value.name + fileType.value)
      })
    }
    const inputRef = ref()

    onMounted(async () => {
      await nextTick()
      inputRef.value.focus()
    })
    return {
      inputRef,
      visibleCompu,
      formRef,
      formData,
      handleCancel,
      handleOk
    }
  }
}
</script>

<style></style>
