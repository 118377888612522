
import { defineComponent, ref } from 'vue'
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { supportedAccepts } from '../config.js'
export default defineComponent({
  name: 'choose-files-modal',
  components: {
    QuestionCircleOutlined,
    CloseOutlined
  },
  emits: ['change'],
  setup(props, ctx) {
    const pptxExchangeTip = ref(false)

    const showPptxExchangeTip = () => {
      pptxExchangeTip.value = !pptxExchangeTip.value
    }

    const handleChooseFiles = (dir: boolean) => {
      let fileInput: HTMLInputElement | null = document.createElement('input')
      fileInput.type = 'file'
      if (dir) {
        fileInput.setAttribute('webkitdirectory', 'webkitdirectory')
        fileInput.setAttribute('directory', 'directory')
      } else {
        fileInput.setAttribute('multiple', 'multiple')
        fileInput.accept = supportedAccepts.join(',')
      }
      fileInput.onchange = (e: any) => {
        // 1. 过滤
        // 2. 整理层级
        const files = Object.values(e.target.files || {}) as File[]
        if (files.length <= 0) {
          ctx.emit('change', [])
          fileInput = null
          return
        }
        const effectiveFiles: any[] = []
        files.reduce((effectiveFiles, currentFile) => {
          const suffix = `.${currentFile.name.split('.')?.reverse()[0]}`
          if (
            supportedAccepts.includes(currentFile.type) ||
            supportedAccepts.includes(suffix)
          ) {
            effectiveFiles.push({
              outputName: currentFile.name.trim(),
              name: currentFile.name.replace(suffix, '').trim(),
              type: currentFile.type,
              suffix,
              path: (currentFile as any).webkitRelativePath,
              paths: (currentFile as any).webkitRelativePath.split('/'),
              files: currentFile
            })
          }
          return effectiveFiles
        }, effectiveFiles)
        ctx.emit('change', effectiveFiles)
        fileInput = null
      }
      fileInput.dispatchEvent(new MouseEvent('click'))
    }
    return {
      pptxExchangeTip,
      showPptxExchangeTip,
      handleChooseFiles
    }
  }
})
