<template>
  <a-card class="card_body">
    <a-row :gutter="[16, 16]" @click="handleGoIntoFolder">
      <a-col :span="6">
        <img class="folder_icon" src="@/assets/coursewares/folder.png" />
      </a-col>
      <a-col :span="18">
        <card-name-title :name="folderName" />
        <div>{{ folderInfo.updated_at }}</div>
      </a-col>
    </a-row>
    <template v-if="folderLook" class="ant-card-actions" #actions>
      <div @click="handleRename">重命名</div>
      <div @click="showDeleteWarning">删除</div>
      <div @click="handleMove">移动到</div>
    </template>
  </a-card>
  <rename-modal v-if="renameVisible" :visible="renameVisible" :value="folderName" :loading='loading'
    :type="folderInfo.type" @onCancel="handleCancelRename" @onOk="handleRenameOk" />
</template>

<script>
import { ref, computed, createVNode } from 'vue'
import RenameModal from './RenameModal.vue'
import { message, Modal } from 'ant-design-vue'
import * as service from '../service'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'
import CardNameTitle from './CardNameTitle.vue'

export default {
  components: { RenameModal, CardNameTitle },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    },
    look: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const folderInfo = computed(() => props.value)
    const folderLook = computed(() => props.look)
    const folderName = ref(folderInfo.value?.name || '新建文件夹')

    const handleGoIntoFolder = () => {
      console.log('click into folder', folderInfo.value.id)
      emit('onEnterFolder', folderInfo.value.id, folderName.value)
    }

    const renameVisible = ref(false)

    const loading = ref(false)

    const handleRename = () => {
      renameVisible.value = true
    }

    const handleCancelRename = () => {
      renameVisible.value = false
    }

    const handleRenameOk = async (name) => {
      try {
        loading.value = true
        const params = {
          name,
          type: folderInfo.value.type,
          parent_id: folderInfo.value.parent_id,
          relation_id: folderInfo.value.relation_id,
          sort: folderInfo.value.sort
        }
        await service.editSpaceNode(folderInfo.value.id, params)
        loading.value = false
        message.success('修改成功')
        handleCancelRename()
        emit('refresh')
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    const handleDelete = async () => {
      try {
        emit('showLoading', true)
        await service.deleteSpaceNode(folderInfo.value.id)
        mineCoursewareStatResport(STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_DELETE, {
          floder_id: folderInfo.value.id
        })
        message.success('删除成功')
        emit('refresh')
        emit('showLoading', false)
        emit('refresh')
      } catch (error) {
        console.log(error)
        emit('showLoading', false)
      }
    }

    const showDeleteWarning = () => {
      Modal.confirm({
        title: '警告',
        icon: createVNode(ExclamationCircleOutlined),
        content: '是否确认删除该文件夹？删除后文件夹内的文件也将被删除！',
        closable: true,
        onOk: handleDelete,
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    const handleMove = () => {
      emit('onMove', folderInfo.value)
    }

    return {
      folderName,
      folderInfo,
      loading,
      renameVisible,
      handleRename,
      handleCancelRename,
      handleRenameOk,
      handleGoIntoFolder,
      showDeleteWarning,
      handleMove,
      folderLook
    }
  }

}
</script>

<style>
.folder_icon {
  width: 100%;
  height: 100%;
}

.card_body {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
