<template>
  <a-card class="card_body">
    <a-row :gutter="[16, 16]" @click="handlePreview">
      <a-col :span="6">
        <img class="courseware_icon" src="@/assets/coursewares/audio_courseware.png" />
      </a-col>
      <a-col :span="18">
        <card-name-title :name="coursewareName"></card-name-title>
        <div>{{ coursewareInfo.updated_at }}</div>
      </a-col>
    </a-row>
    <template v-if="coursewareLook" class="ant-card-actions" #actions>
      <div @click="handlePreview">播放</div>
      <div>
        <div
          v-if="coursewareInfo.courseware.share_mode === undefined || coursewareInfo.courseware.share_mode === shareModeEnum.UNLIMIT"
          @click="handleShare">
          <div>分享</div>
        </div>
        <div v-else style="cursor:not-allowed">分享</div>
      </div>
      <div @click="handleRename">重命名</div>
      <a-dropdown trigger="click">
        <span @click.prevent>更多</span>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="handleMove">
              <div>移动到</div>
            </a-menu-item>

            <a-menu-item @click="showDeleteWarning">
              <div style="color: red">删除</div>
            </a-menu-item>
            <!-- <a-menu-item @click="handleDownload">
              <div >下载</div>
            </a-menu-item> -->
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-card>
  <a-row v-if="!coursewareLook" class="file">
    <a-col :span="24">
      <div @click="handlePreview">播放</div>
    </a-col>
  </a-row>
  <a-modal style="top: 200px" v-if="previewVisible" :visible="previewVisible" :footer="null" @cancel="handleCancelPreview"
    :title="coursewareName">
    <audio v-nopreventright style="width: 100%" controls :src="fileInfo.url" controlslist="nodownload"></audio>
  </a-modal>
  <rename-modal v-if="renameVisible" :value="coursewareName" :visible="renameVisible" :loading="loading"
    @onCancel="handleRenameCancel" @onOk="handleRenameOk" />
</template>

<script>
import { computed, ref } from 'vue'
import RenameModal from './RenameModal.vue'
import { message, Modal } from 'ant-design-vue'
import * as service from '../service'
import { downLoadFromOss } from '@/utils/oss'
import { shareModeEnum } from '@/utils/dataMap'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'
import CardNameTitle from './CardNameTitle.vue'

export default {
  components: { RenameModal, CardNameTitle },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    },
    look: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const coursewareInfo = computed(() => props.value)
    const coursewareLook = computed(() => props.look)
    const coursewareName = ref(coursewareInfo.value.name || '')
    const fileInfo = ref(coursewareInfo.value.courseware)

    const previewVisible = ref(false)

    const renameVisible = ref(false)

    const loading = ref(false)

    const handlePreview = () => {
      previewVisible.value = true
    }

    const handleCancelPreview = () => { previewVisible.value = false }

    const handleDownload = () => {
      downLoadFromOss(fileInfo.value.url, fileInfo.value.name)
    }

    const handleRename = () => {
      renameVisible.value = true
    }

    const handleRenameCancel = () => {
      renameVisible.value = false
    }

    const handleRenameOk = async (name) => {
      try {
        loading.value = true
        const params = {
          name
        }
        await service.renameCourseware(fileInfo.value.id, params)
        loading.value = false
        message.success('修改成功')
        handleRenameCancel()
        emit('refresh')
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    const handleDelete = async () => {
      try {
        emit('showLoading', true)
        await service.deleteTeacherCourseware(fileInfo.value.id)
        mineCoursewareStatResport(STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_DELETE, {
          courseware_id: fileInfo.value.id,
          courseware_type: fileInfo.value.type
        })
        message.success('删除成功')
        emit('refresh')
        emit('showLoading', false)
        emit('refresh')
      } catch (error) {
        console.log(error)
        emit('showLoading', false)
      }
    }

    const showDeleteWarning = () => {
      Modal.confirm({
        title: '警告',
        content: '是否确认删除该文件？删除后将不可恢复',
        closable: true,
        onOk: handleDelete,
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    const handleMove = () => {
      emit('onMove', coursewareInfo.value)
    }

    // 分享课件
    const handleShare = () => {
      emit('onShare', coursewareInfo.value)
    }

    return {
      coursewareInfo,
      coursewareName,
      previewVisible,
      fileInfo,
      renameVisible,
      loading,
      shareModeEnum,
      handlePreview,
      handleCancelPreview,
      handleDownload,
      handleRename,
      handleRenameCancel,
      handleRenameOk,
      handleDelete,
      showDeleteWarning,
      handleMove,
      handleShare,
      coursewareLook
    }
  }

}
</script>

<style scoped>
.card_body {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.courseware_icon {
  width: 100%;
}

.file {
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  background-color: #fafafa;
  border: 1px solid rgb(240, 240, 240);
  padding: 10px 0;
  text-align: center;
}
</style>
