export const DirectoryItemType = {
  FOLDER: 0,
  COURSEWARE: 1
}

export const CoursewareType = {
  PPT: 'ppt',
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  FLASH: 'swf',
  SPEAK: 'speak',
  Plan: 'plan'
}

// 支持上传的格式
export const supportedFormats = {
  IMAGE: ['jpeg', 'jpg', 'png'],
  AUDIO: ['mp3', 'wav', 'ogg', 'aac'],
  VIDEO: ['mp4', 'mov'],
  FLASH: ['swf']
}
/** 支持上传的accepts */
export const supportedAccepts = [
  '.png',
  '.jpeg',
  '.jpg',
  '.mp3',
  '.wav',
  '.ogg',
  '.aac',
  '.mp4',
  '.mov',
  '.json',
  '.swf',
  '.pptx'
]

export const supportedSpeakFormats = {
  SPEAK: ['mp4']
}

export const shareModeMap = {
  UNLIMIT: 0,
  NO_GET: 1,
  CAN_GET_AND_NOT_EDIT: 2,
  CAN_GET_EDIT_AND_NO_SHARE: 3
}

export const CoursewareTypeOpts = [
  { label: 'PPT', value: 'ppt' },
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '图片', value: 'image' },
  { label: 'flash', value: 'swf' },
  { label: '说课', value: 'speak' },
  { label: '教案', value: 'plan' }
]

// table中使用
export const CoursewareTypeMap = {
  ppt: 'PPT',
  audio: '音频',
  video: '视频',
  image: '图片',
  swf: 'flash动画',
  speak: '说课',
  plan: '教案'
}

export const ShareStatusMap = {
  0: { label: '待审核', color: '#108ee9' },
  1: { label: '通过', color: '#87d068' },
  2: { label: '未通过', color: '#f50' },
  3: { label: '已取消', color: '#9f9f9f' }
}

export const ShareStatusTypeMap = {
  WAIT: 0,
  SUCCESS: 1,
  FAIL: 2
}

export const PublishTypeMap = {
  0: { label: '平台' },
  1: { label: '学校' },
  2: { label: '区域' }
}
