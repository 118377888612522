<script lang="ts">
import { defineComponent, ref } from 'vue'
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { supportedAccepts } from '../config.js'
export default defineComponent({
  name: 'choose-files-modal',
  components: {
    QuestionCircleOutlined,
    CloseOutlined
  },
  emits: ['change'],
  setup(props, ctx) {
    const pptxExchangeTip = ref(false)

    const showPptxExchangeTip = () => {
      pptxExchangeTip.value = !pptxExchangeTip.value
    }

    const handleChooseFiles = (dir: boolean) => {
      let fileInput: HTMLInputElement | null = document.createElement('input')
      fileInput.type = 'file'
      if (dir) {
        fileInput.setAttribute('webkitdirectory', 'webkitdirectory')
        fileInput.setAttribute('directory', 'directory')
      } else {
        fileInput.setAttribute('multiple', 'multiple')
        fileInput.accept = supportedAccepts.join(',')
      }
      fileInput.onchange = (e: any) => {
        // 1. 过滤
        // 2. 整理层级
        const files = Object.values(e.target.files || {}) as File[]
        if (files.length <= 0) {
          ctx.emit('change', [])
          fileInput = null
          return
        }
        const effectiveFiles: any[] = []
        files.reduce((effectiveFiles, currentFile) => {
          const suffix = `.${currentFile.name.split('.')?.reverse()[0]}`
          if (
            supportedAccepts.includes(currentFile.type) ||
            supportedAccepts.includes(suffix)
          ) {
            effectiveFiles.push({
              outputName: currentFile.name.trim(),
              name: currentFile.name.replace(suffix, '').trim(),
              type: currentFile.type,
              suffix,
              path: (currentFile as any).webkitRelativePath,
              paths: (currentFile as any).webkitRelativePath.split('/'),
              files: currentFile
            })
          }
          return effectiveFiles
        }, effectiveFiles)
        ctx.emit('change', effectiveFiles)
        fileInput = null
      }
      fileInput.dispatchEvent(new MouseEvent('click'))
    }
    return {
      pptxExchangeTip,
      showPptxExchangeTip,
      handleChooseFiles
    }
  }
})
</script>

<template>
  <div class="choose-files-modal">
    <div class="tip-text">
      <p>1、支持png,jpeg,jpg,mov,mp4,mp3,wav,ogg,aac,flash,pptx文件格式</p>
      <p>
        2、若是本地打开了准备上传的文件，可能会导致上传失败，请关闭文件重新上传
      </p>
      <p>
        3、ppt需要转化为“.pptx”后缀
        <QuestionCircleOutlined
          :style="{ fontSize: '14px', color: '#ff812d', cursor: 'pointer' }"
          @click="showPptxExchangeTip"
        />
      </p>
    </div>
    <div class="choose-btns">
      <a-button type="primary" @click="handleChooseFiles(false)"
        >文件上传</a-button
      >
      <a-button type="primary" @click="handleChooseFiles(true)"
        >文件夹导入</a-button
      >
    </div>
  </div>
  <Teleport to="#app">
    <div class="pptx-exchange-tip" v-if="pptxExchangeTip">
      <div class="pptx-exchange-tip_mask" @click="showPptxExchangeTip"></div>
      <div class="pptx-exchange-tip_content">
        <CloseOutlined
          @click="showPptxExchangeTip"
          class="close-icon"
        ></CloseOutlined>
        <img src="@/assets/exchange-pptx.jpg" />
      </div>
    </div>
  </Teleport>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.choose-files-modal {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(102, 102, 102, 0.5);
  border-top: 2px solid #1ec38f;
  padding: 20px;
  .tip-text {
    font-size: 14px;
    p{
      margin-bottom: 5px;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
  .choose-btns {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.pptx-exchange-tip {
  position: fixed;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .pptx-exchange-tip_mask {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
  }
  .pptx-exchange-tip_content {
    width: 500px;
    height: 380px;
    padding: 20px;
    text-align: center;
    position: absolute;
    box-shadow: 0px 0px 20px #0000001f;
    border: 1px solid #e4e7ed;
    background-color: #ffffff;
    overflow: hidden;
    color: #303133;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
