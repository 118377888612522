<template>
    <a-modal
      :visible="visibleCompu"
      :maskClosable="false"
      :okButtonProps="{ loading: loadingCompu }"
      :cancelButtonProps="{ disabled: loadingCompu }"
      @ok="handleSubmit"
      @cancel="showCancel"
    >
      <template #title>
        <span>上传文件</span>
        <a-tooltip placement="top">
          <template #title>
            <p>支持上传的文件格式如下</p>
            <p>
              <span>说课</span>
              <span v-for="v in supportedSpeakFormats.SPEAK" :key="v"
                >&nbsp;{{ v }}</span
              >
            </p>
          </template>
          <QuestionCircleOutlined style="opacity: 0.5; margin-left: 12px" />
        </a-tooltip>
      </template>
      <a-form ref="formRef" :model="formData">
        <a-form-item
          label="文件名"
          name="name"
          :rules="{ required: true, message: '请输入文件名', tigger: 'blur' }"
        >
          <a-input v-model:value="formData.name"></a-input>
        </a-form-item>
        <a-form-item label="选择文件">
          <div>
            <a-upload
              name="upload_file"
              :file-list="null"
              :multiple="false"
              :accept="supportedFormatsString"
              :before-upload="handleBeforeUpload"
            >
              <a-button :disabled="uploadLoading || loadingCompu">
                <UploadOutlined />{{
                  currentFile === null ? "选择文件" : "重选文件"
                }}
              </a-button>
            </a-upload>
            <span style="margin-top: 10px">{{ fileName }}</span>
            <a-progress
              v-if="uploadPrecent > 0"
              :percent="uploadPrecent"
              :status="uploadStatus"
            ></a-progress>
            <div class="upload_opt_btn">
              <a-button
                type="primary"
                @click="handleUpload"
                :loading="uploadLoading"
                :disabled="loadingCompu"
              >
                开始上传
              </a-button>
              <a-button
                class="clean_btn"
                type="primary"
                danger
                @click="handleCleanAll"
                :disabled="uploadLoading || loadingCompu"
              >
                清空
              </a-button>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="关联课件" name="course">
          <a-row :gutter="10">
            <a-col :span="12" v-if="optionsValue.length > 0">
              <a-tag
                style="height: 32px; font-size: medium; padding-top: 3px;"
                :closable="closableTag"
                @close="handleClose(tag)"
                :key="tag.value"
                v-for="tag in optionsValue">
                {{tag.label}}
              </a-tag>
            </a-col>
            <a-col>
              <a-button @click="treeSelect">添加</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="文件大小" name="size">
          <a-input v-model:value="formData.size" :disabled="true"></a-input>
        </a-form-item>
        <a-form-item label="文件类型" name="type">
          <a-input v-model:value="formData.type" :disabled="true"></a-input>
        </a-form-item>
      </a-form>
      <div style="z-index: 10">
        <select-relate-teacher-mine-courseware-modal
          :visible="visibleValue"
          @onCancel="handleShut"
          @ok="handleOk"
          :relate-courseware-ids="relateState.collected_ids"
          :coursewareType="'teacher'"
          :relateType="'ppt'"
        />
      </div>
    </a-modal>
  </template>

<script>

import { computed, ref, toRaw, onMounted, reactive, watch, createVNode } from 'vue'
import { uploadFileToOss } from '@/utils/oss'
import { UploadOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import { supportedSpeakFormats, CoursewareType } from '../config.js'
import * as service from '../service'
import SearchBar from '@/components/common/SearchBar.vue'
import { pull } from 'lodash'
import SelectRelateTeacherMineCoursewareModal from '@/views/courseware/components/SelectRelateTeacherMineCoursewareModal.vue'

export default {
  components: {
    UploadOutlined,
    QuestionCircleOutlined,
    SelectRelateTeacherMineCoursewareModal
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    lesson: {
      type: Array // 可关联ppt的id
    },
    speaking: {
      type: Boolean // 新建、编辑判断
    }
  },
  setup (props, { emit }) {
    // 可见度
    const visibleCompu = computed(() => props.visible)

    const formRef = ref()
    const formData = ref({})

    const uploadLoading = ref(false)

    const loadingCompu = computed(() => props.loading)

    // 我的课件id
    const selectVal = computed(() => props.lesson)

    // 上传相关
    const fileName = ref('')
    // 当前文件
    const currentFile = ref(null)
    // 进度条相关
    const uploadPrecent = ref(0)
    const uploadStatus = ref('normal')

    // 关联课件选项值
    const optionsValue = ref([])
    // 新建、编辑判断
    const editData = computed(() => props.speaking)

    // 关联课件弹框
    const visibleValue = ref(false)

    // id
    const uploadId = editData.value ? 0 : selectVal.value

    // tag的取消
    const closableTag = ref(false)

    // 数据变化是返回false
    const changeData = ref(true)

    const relateState = reactive({
      collected_ids: []
    })

    const handleBeforeUpload = (file) => {
      currentFile.value = file
      fileName.value = file.name
      console.log(file)
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      return false
    }

    const supportedFormatsString = Object.values(supportedSpeakFormats.SPEAK).flat().map(x => '.' + x).join(',')

    // 选中后弹窗
    const treeSelect = () => {
      visibleValue.value = true
    }

    const showConfirm = () => {
      Modal.confirm({
        title: '有尚未保存的说课内容，确定要离开吗?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk () {
          handleCancel()
        }
      })
    }

    onMounted(async () => {
      if (editData.value === false) {
        const data = await service.getTeacherCourseware(uploadId)
        const app = data.relate_coursewares.map(({ id, name }) => ({ value: id, label: name }))
        const item = data.relate_coursewares.map((i) => i.id)
        relateState.collected_ids = item
        formData.value = data
        formData.value.relate_coursewares = item
        uploadPrecent.value = 100
        currentFile.value = 'name'
        if (app[0] === undefined) {
          closableTag.value = false
        } else {
          optionsValue.value = app
          closableTag.value = true
        }
      }
      watch([() => formData.value, () => optionsValue.value], () => {
        changeData.value = false
      }, { deep: true })
    })

    // tag取消调用
    const handleClose = (tag) => {
      pull(optionsValue.value, tag)
      pull(relateState.collected_ids, tag.value)
      relateState.collected_ids = relateState.collected_ids.filter(i => tag.value !== i.id)
    }

    // 关联课件弹窗确定按钮
    const handleOk = () => {
      visibleValue.value = false
      closableTag.value = true
      const relate_ppts = JSON.parse(localStorage.getItem('relate_ppts'))
      relateState.collected_ids = relate_ppts.map((i) => i.courseware?.id)
      optionsValue.value = relate_ppts.map(i => ({ value: i.courseware?.id, label: i.name }))
    }

    const onPrecentChange = (precent) => {
      uploadPrecent.value = precent
    }

    const handleUpload = async () => {
      if (currentFile.value) {
        try {
          uploadLoading.value = true
          uploadStatus.value = 'normal'
          const fileType = currentFile.value.type.substring(0, currentFile.value.type.lastIndexOf('/'))
          const suffix = currentFile.value.name.slice(currentFile.value.name.lastIndexOf('.') + 1)
          // 兼容flash文件的格式
          if (
            (Object.values(CoursewareType).includes(fileType) && Object.values(supportedSpeakFormats).flat().includes(suffix)) ||
              (fileType === 'application' && Object.values(supportedSpeakFormats).flat().includes(suffix) && currentFile.value.type === 'application/x-shockwave-flash')
          ) {
            const data = await uploadFileToOss(currentFile.value, true, onPrecentChange)
            formData.value = {
              ...toRaw(formData.value),
              url: data.url,
              md5: data.md5,
              size: data.size,
              type: 'speak',
              file: fileName.value
            }
            uploadStatus.value = 'success'
            message.success('上传成功')
          } else {
            message.error('不支持该文件格式')
          }
          uploadLoading.value = false
        } catch (error) {
          uploadLoading.value = false
          console.log(error)
          uploadStatus.value = 'exception'
          message.error('上传失败，请重试')
        }
      }
    }

    const handleCleanAll = () => {
      currentFile.value = null
      fileName.value = ''
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      uploadLoading.value = false
      formData.value = {
        ...toRaw(formData.value),
        url: undefined,
        md5: undefined,
        size: undefined,
        type: undefined,
        file: undefined
      }
    }

    const handleSubmit = () => {
      formRef.value.validate().then(() => {
        if (formData.value.url === undefined) {
          message.error('请选择要上传的文件')
          return
        }
        emit('onOk', formData.value, relateState.collected_ids)
      })
    }

    const handleShut = () => {
      visibleValue.value = false
    }

    const showCancel = () => {
      if (changeData.value === false) {
        showConfirm()
      } else {
        handleCancel()
      }
    }

    const handleCancel = () => {
      emit('onCancel')
    }

    return {
      visibleCompu,
      loadingCompu,
      formRef,
      formData,
      currentFile,
      uploadLoading,
      uploadPrecent,
      uploadStatus,
      fileName,
      supportedFormatsString,
      supportedSpeakFormats,
      selectVal,
      optionsValue,
      handleSubmit,
      handleCancel,
      handleBeforeUpload,
      handleUpload,
      handleCleanAll,
      visibleValue,
      SearchBar,
      handleOk,
      relateState,
      uploadId,
      handleClose,
      treeSelect,
      handleShut,
      closableTag,
      showConfirm,
      changeData,
      showCancel
    }
  }
}
</script>

  <style scoped lang="less">
  .tag_size{
    font-size: large;
  }
  .upload_opt_btn {
    margin-top: 10px;
  }

  .clean_btn {
    margin-left: 10px;
  }
  </style>
