<template>
  <a-modal
    :visible="visibleCompu"
    title="移动到"
    destroyOnClose
    :maskClosable="false"
    :okButtonProps="{ loading: loadingCompu }"
    :cancelButtonProps="{ disabled: loadingCompu }"
    @ok="handleSelectOk"
    @cancel="handleCancel"
  >
    <a-tree-select
      v-model:value="value"
      tree-data-simple-mode
      :tree-data="treeData"
      placeholder="请选择要移动到的目录"
      :load-data="onLoadData"
      style="width: 100%"
    >
    </a-tree-select>
  </a-modal>
</template>

<script>
import { ref, computed, onMounted, toRaw } from 'vue'
import * as service from '../service'

export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    currentNode: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const visibleCompu = computed(() => props.visible)
    const loadingCompu = computed(() => props.loading)

    const currentNode = computed(() => props.currentNode)

    const value = ref('')

    const treeData = ref([])

    const getTreeInfo = async (id) => {
      const data = await service.queryTeacherCoursewareList({ parent_id: id, type: 0 })
      const list = data.items.map(item => {
        return {
          id: item.id,
          pId: item.parent_id,
          value: item.id,
          title: item.name,
          disabled: currentNode.value.id === item.id || item.id === currentNode.value.parent_id,
          isLeaf: currentNode.value.id === item.id
        }
      })
      return list
    }

    const onLoadData = (treeNode) => {
      return new Promise((resolve, reject) => {
        const id = treeNode.dataRef.id
        getTreeInfo(id).then(list => {
          console.log(list)
          treeData.value = [...toRaw(treeData.value), ...list]
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }

    const handleCancel = () => {
      emit('onCancel')
    }

    const handleSelectOk = () => {
      emit('onOk', value.value)
      emit('onCancel')
    }

    onMounted(async () => {
      const rootNode = {
        id: 0,
        value: 0,
        title: '我的课件'
      }
      treeData.value = [rootNode]
    })

    return {
      visibleCompu,
      loadingCompu,
      value,
      treeData,
      getTreeInfo,
      handleCancel,
      handleSelectOk,
      onLoadData
    }
  }
}
</script>

<style>
</style>
