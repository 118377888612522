<template>
  <a-modal
    :visible="visibleCompu"
    :maskClosable="false"
    :okButtonProps="{ loading: loadingCompu }"
    :cancelButtonProps="{ disabled: loadingCompu }"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <template #title>
      <span>上传文件</span>
      <a-tooltip placement="top">
        <template #title>
          <p>支持上传的文件格式如下</p>
          <p>
            <span>图片</span
            ><span v-for="v in supportedFormats.IMAGE" :key="v"
              >&nbsp;{{ v }}</span
            >
          </p>
          <p>
            <span>视频</span
            ><span v-for="v in supportedFormats.VIDEO" :key="v"
              >&nbsp;{{ v }}</span
            >
          </p>
          <p>
            <span>音频</span
            ><span v-for="v in supportedFormats.AUDIO" :key="v"
              >&nbsp;{{ v }}</span
            >
          </p>
          <p>
            <span>Flash动画</span>
            <span v-for="v in supportedFormats.FLASH" :key="v"
              >&nbsp;{{ v }}</span
            >
          </p>
        </template>
        <QuestionCircleOutlined style="opacity: 0.5; margin-left: 12px" />
      </a-tooltip>
    </template>
    <a-form ref="formRef" :model="formData">
      <a-form-item
        label="文件名"
        name="name"
        :rules="{ required: true, message: '请输入文件名', tigger: 'blur' }"
      >
        <a-input v-model:value="formData.name"></a-input>
      </a-form-item>
      <a-form-item label="选择文件">
        <div>
          <a-upload
            name="upload_file"
            :file-list="null"
            :multiple="false"
            :accept="supportedFormatsString"
            :before-upload="handleBeforeUpload"
          >
            <a-button :disabled="uploadLoading || loadingCompu">
              <UploadOutlined />{{
                currentFile === null ? "选择文件" : "重选文件"
              }}
            </a-button>
          </a-upload>
          <span style="margin-top: 10px">{{ fileName }}</span>
          <a-progress
            v-if="uploadPrecent > 0"
            :percent="uploadPrecent"
            :status="uploadStatus"
          ></a-progress>
          <div class="upload_opt_btn">
            <a-button
              type="primary"
              @click="handleUpload"
              :loading="uploadLoading"
              :disabled="loadingCompu"
            >
              开始上传
            </a-button>
            <a-button
              class="clean_btn"
              type="primary"
              danger
              @click="handleCleanAll"
              :disabled="uploadLoading || loadingCompu"
            >
              清空
            </a-button>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="文件大小" name="size">
        <a-input v-model:value="formData.size" :disabled="true"></a-input>
      </a-form-item>
      <a-form-item label="文件类型" name="type">
        <a-input v-model:value="formData.type" :disabled="true"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import { computed, ref, toRaw } from 'vue'
import { uploadFileToOss } from '@/utils/oss'
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { supportedFormats, CoursewareType } from '../config.js'

export default {
  components: {
    UploadOutlined,
    QuestionCircleOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props, { emit }) {
    // 可见度
    const visibleCompu = computed(() => props.visible)

    const formRef = ref()
    const formData = ref({})

    const uploadLoading = ref(false)

    const loadingCompu = computed(() => props.loading)
    // 上传相关
    const fileName = ref('')
    // 当前文件
    const currentFile = ref(null)
    // 进度条相关
    const uploadPrecent = ref(0)
    const uploadStatus = ref('normal')

    const handleBeforeUpload = (file) => {
      currentFile.value = file
      fileName.value = file.name
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      return false
    }

    const supportedFormatsString = Object.values(supportedFormats).flat().map(x => '.' + x).join(',')

    const onPrecentChange = (precent) => {
      uploadPrecent.value = precent
    }

    const handleUpload = async () => {
      if (currentFile.value) {
        try {
          uploadLoading.value = true
          uploadStatus.value = 'normal'
          const fileType = currentFile.value.type.substring(0, currentFile.value.type.lastIndexOf('/'))
          const suffix = currentFile.value.name.slice(currentFile.value.name.lastIndexOf('.') + 1)
          console.log(currentFile, suffix)
          // 兼容flash文件的格式
          if (
            (Object.values(CoursewareType).includes(fileType) && Object.values(supportedFormats).flat().includes(suffix)) ||
            (fileType === 'application' && Object.values(supportedFormats).flat().includes(suffix) && currentFile.value.type === 'application/x-shockwave-flash')
          ) {
            const data = await uploadFileToOss(currentFile.value, true, onPrecentChange)
            formData.value = {
              ...toRaw(formData.value),
              url: data.url,
              md5: data.md5,
              size: data.size,
              type: fileType === 'application' ? suffix : fileType
            }
            uploadStatus.value = 'success'
            message.success('上传成功')
          } else {
            message.error('不支持该文件格式')
          }
          uploadLoading.value = false
        } catch (error) {
          uploadLoading.value = false
          console.log(error)
          uploadStatus.value = 'exception'
          message.error('上传失败，请重试')
        }
      }
    }

    const handleCleanAll = () => {
      currentFile.value = null
      fileName.value = ''
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      uploadLoading.value = false
      formData.value = {
        ...toRaw(formData.value),
        url: undefined,
        md5: undefined,
        size: undefined,
        type: undefined
      }
    }

    const handleSubmit = () => {
      formRef.value.validate().then(() => {
        if (formData.value.url === undefined) {
          message.error('请选择要上传的文件')
          return
        }
        emit('onOk', formData.value)
      })
    }

    const handleCancel = () => {
      emit('onCancel')
    }

    return {
      visibleCompu,
      loadingCompu,
      formRef,
      formData,
      currentFile,
      uploadLoading,
      uploadPrecent,
      uploadStatus,
      fileName,
      supportedFormatsString,
      supportedFormats,
      handleSubmit,
      handleCancel,
      handleBeforeUpload,
      handleUpload,
      handleCleanAll
    }
  }
}
</script>

<style>
.upload_opt_btn {
  margin-top: 10px;
}

.clean_btn {
  margin-left: 10px;
}
</style>
