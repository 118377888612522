<template>
  <a-modal
    :visible="visible"
    title="新建文件夹"
    :maskClosable="false"
    destroyOnClose
    :okButtonProps="{ loading: loadingCompu }"
    :cancelButtonProps="{ disabled: loadingCompu }"
    @ok="handleInputOk"
    @cancel="handleCancel"
  >
    <a-form ref="formRef" :model="formData">
      <a-form-item
        label="文件夹名称"
        name="name"
        :rules="{ required: true, message: '请输入名称', trigger: 'blur' }"
      >
        <a-input
           ref="inputRef"
          v-model:value="formData.name"
          placeholder="请输入新建文件夹的名称"
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, ref, onMounted, nextTick } from 'vue'
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props, { emit }) {
    const visibleCompu = computed(() => props.visible)

    const loadingCompu = computed(() => props.loading)

    const formData = ref({})

    const formRef = ref()

    const inputRef = ref()
    const handleInputOk = () => {
      formRef.value.validate().then(() => {
        emit('onOk', formData.value.name)
      })
    }

    const handleCancel = () => {
      emit('onCancel')
    }
    onMounted(async () => {
      await nextTick()
      inputRef.value.focus()
    })

    return {
      formData,
      visibleCompu,
      formRef,
      loadingCompu,
      handleInputOk,
      handleCancel,
      inputRef
    }
  }

}
</script>

<style>
</style>
