<template>
  <a-card class="card_body">
    <a-row :gutter="[16, 16]" @click="handleEnterPPT">
      <a-col :span="6">
        <img class="courseware_icon" src="@/assets/coursewares/plan_courseware.png" />
      </a-col>
      <a-col :span="18">
        <card-name-title :name="coursewareName" />
        <div>{{ coursewareInfo.updated_at }}</div>
      </a-col>
    </a-row>
    <template v-if="coursewareLook" class="ant-card-actions" #actions>
      <div @click="handlePreview">预览</div>
      <div>
        <!-- v-if中兼容搜索模式 -->
        <div
          v-if="coursewareInfo.courseware.share_mode === undefined || coursewareInfo.courseware.share_mode === shareModeEnum.UNLIMIT"
          @click="handleShare">
          <div>分享</div>
        </div>
        <div v-else style="cursor:not-allowed">分享</div>
      </div>
      <div @click="handleRename">重命名</div>
      <a-dropdown trigger="click">
        <span @click.prevent>更多</span>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="handleEnterPPT">
              <div>编辑</div>
            </a-menu-item>
            <a-menu-item @click="handleMove">
              <div>移动到</div>
            </a-menu-item>
            <a-menu-item @click="showDeleteWarning">
              <div style="color: red">删除</div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-card>
  <a-row v-if="!coursewareLook" class="file">
    <a-col :span="24">
      <div @click="handlePreview">预览</div>
    </a-col>
  </a-row>
  <rename-modal v-if="renameVisible" :visible="renameVisible" :value="coursewareName" :loading="loading"
    :type="fileInfo.type" @onCancel="handleRenameCancel" @onOk="handleRenameOk" />
</template>

<script>
import { computed, createVNode, ref, onMounted } from 'vue'
import { CoursewareType } from '../config'
import { useRouter } from 'vue-router'
import * as service from '../service'
import { message, Modal } from 'ant-design-vue'
import RenameModal from './RenameModal.vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { downLoadFromOss } from '@/utils/oss'
import { shareModeEnum } from '@/utils/dataMap'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'
import CardNameTitle from './CardNameTitle.vue'

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Object,
      default: () => null
    },
    look: {
      type: Boolean,
      default: () => false
    },
    checkPersonalCloudSpace: {
      type: Function,
      default: () => { return false }
    }
  },
  components: {
    RenameModal,
    CardNameTitle
  },
  setup(props, { emit }) {
    // ppt基础信息
    const coursewareInfo = computed(() => props.value)
    const coursewareLook = computed(() => props.look)

    // 课件所在文件节点
    const courseNode = computed(() => props.data)

    const coursewareName = ref(coursewareInfo.value.name || '')

    const fileInfo = ref(coursewareInfo.value.courseware)

    const renameVisible = ref(false)

    const loading = ref(false)

    const router = useRouter()

    const handleEnterPPT = async() => {
      if (fileInfo.value.share_mode === shareModeEnum.NOEDIT) {
        message.error('该教案不允许编辑')
        return
      }
      console.log('click ===>1 ', coursewareInfo.value)
      if (props.checkPersonalCloudSpace) {
        try {
          const canAction = await props.checkPersonalCloudSpace()
          if (canAction) {
            emit('onEdit')
            router.push(`/resource/mine-coursewares/edit/${fileInfo.value.id}/plan/0/${courseNode.value.id}`)
          }
        } catch (err) {
          console.error('检查个人空间容量失败')
        }
      }
    }

    const handleDownload = () => {
      downLoadFromOss(fileInfo.value.url, fileInfo.value.name)
    }

    const handlePreview = () => {
      emit('onPreview')
      router.push(`/resource/mine-coursewares/preview/${fileInfo.value.id}/plan/0/${courseNode.value.id}`)
    }

    const handleRename = () => {
      renameVisible.value = true
    }

    const handleRenameCancel = () => {
      renameVisible.value = false
    }

    const handleRenameOk = async name => {
      try {
        loading.value = true
        const params = {
          name
        }
        await service.renameCourseware(fileInfo.value.id, params)
        loading.value = false
        message.success('修改成功')
        handleRenameCancel()
        emit('refresh')
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    const handleDelete = async () => {
      console.log('handleDelete')
      try {
        emit('showLoading', true)
        await service.deleteTeacherCourseware(fileInfo.value.id)
        mineCoursewareStatResport(STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_DELETE, {
          courseware_id: fileInfo.value.id,
          courseware_type: fileInfo.value.type
        })
        message.success('删除成功')
        emit('refresh')
        emit('showLoading', false)
        emit('refresh')
      } catch (error) {
        console.log(error)
        emit('showLoading', false)
      }
    }

    const showDeleteWarning = () => {
      Modal.confirm({
        title: '警告',
        icon: createVNode(ExclamationCircleOutlined),
        content: '是否确认删除该文件？删除后将不可恢复',
        closable: true,
        onOk: handleDelete,
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const handleMove = () => {
      emit('onMove', coursewareInfo.value)
    }

    // 分享课件
    const handleShare = () => {
      emit('onShare', coursewareInfo.value)
    }

    return {
      fileInfo,
      coursewareInfo,
      coursewareName,
      CoursewareType,
      renameVisible,
      loading,
      shareModeEnum,
      handleDownload,
      handleEnterPPT,
      handlePreview,
      handleRenameOk,
      handleRename,
      handleRenameCancel,
      showDeleteWarning,
      handleDelete,
      handleMove,
      handleShare,
      coursewareLook
    }
  }
}
</script>

<style scoped>
.card_body {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.courseware_icon {
  width: 100%;
}

.file {
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  background-color: #fafafa;
  border: 1px solid rgb(240, 240, 240);
  padding: 10px 0;
  text-align: center;
}
</style>
