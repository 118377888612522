
import _ from 'lodash'
import { defineComponent, onMounted, ref } from 'vue'
import { getCloudSpaceInfo } from '../service'

export default defineComponent({
  name: 'personal-cloud-space',
  setup() {
    const cloudSpace = ref({
      spaceLimit: '0KB',
      coursewareSize: '0KB',
      melodySize: '0KB',
      totalSize: '0KB'
    })
    const progressPercent = ref(0)
    const computedSpace = (data: number) => {
      return data / 1024 > 1024
        ? data / 1024 / 1024 > 1024
          ? (data / 1024 / 1024 / 1024).toFixed(1) + 'GB'
          : (data / 1024 / 1024).toFixed(1) + 'MB'
        : (data / 1024).toFixed(1) + 'KB'
    }
    const getPersonalCloudSpace = () => {
      return new Promise((resolve, reject) => {
        getCloudSpaceInfo().then((res:any) => {
          if (res) {
            progressPercent.value = res?.total_size /
              (res?.space_limit || 1) * 100
            _.merge(cloudSpace.value, {
              coursewareSize: computedSpace(res?.courseware_size || 0),
              spaceLimit: computedSpace(res?.space_limit || 0),
              melodySize: computedSpace(res?.melody_size || 0),
              totalSize: computedSpace(res?.total_size || 0)
            })
          }
          resolve('')
        }).catch(err => {
          console.error('获取个人云空间信息失败：', err?.message)
          reject(err)
        })
      })
    }
    onMounted(() => {
      getPersonalCloudSpace()
    })
    return {
      cloudSpace,
      progressPercent,
      getPersonalCloudSpace
    }
  }
})
