<template>
  <div class="personal-cloud-space">
    <div class="title">云空间</div>
    <div class="progress-bar">
      <div class="progress-fill" :style="{ width:progressPercent  + '%' }" :class="[
          progressPercent > 70
            ? progressPercent < 100
              ? 'percent-middle'
              : 'percent-full'
            : 'percent-low'
        ]"></div>
      <a-popover title="云空间使用情况"  overlayClassName="popover-cloud-space" >
    <template #content>
<!-- <div class="popover-cloud-space-title">云空间使用情况</div> -->
<div class="popover-cloud-space-item">
  <div class="label">课件</div>
  <div class="value">{{ cloudSpace.coursewareSize }}</div>
</div>
<div class="popover-cloud-space-item">
  <div class="label">音乐律动</div>
  <div class="value">{{ cloudSpace.melodySize }}</div>
</div>
    </template>
    <div class="progress-text">{{ cloudSpace?.totalSize }}/{{ cloudSpace?.spaceLimit }}</div>
  </a-popover>

    </div>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import { defineComponent, onMounted, ref } from 'vue'
import { getCloudSpaceInfo } from '../service'

export default defineComponent({
  name: 'personal-cloud-space',
  setup() {
    const cloudSpace = ref({
      spaceLimit: '0KB',
      coursewareSize: '0KB',
      melodySize: '0KB',
      totalSize: '0KB'
    })
    const progressPercent = ref(0)
    const computedSpace = (data: number) => {
      return data / 1024 > 1024
        ? data / 1024 / 1024 > 1024
          ? (data / 1024 / 1024 / 1024).toFixed(1) + 'GB'
          : (data / 1024 / 1024).toFixed(1) + 'MB'
        : (data / 1024).toFixed(1) + 'KB'
    }
    const getPersonalCloudSpace = () => {
      return new Promise((resolve, reject) => {
        getCloudSpaceInfo().then((res:any) => {
          if (res) {
            progressPercent.value = res?.total_size /
              (res?.space_limit || 1) * 100
            _.merge(cloudSpace.value, {
              coursewareSize: computedSpace(res?.courseware_size || 0),
              spaceLimit: computedSpace(res?.space_limit || 0),
              melodySize: computedSpace(res?.melody_size || 0),
              totalSize: computedSpace(res?.total_size || 0)
            })
          }
          resolve('')
        }).catch(err => {
          console.error('获取个人云空间信息失败：', err?.message)
          reject(err)
        })
      })
    }
    onMounted(() => {
      getPersonalCloudSpace()
    })
    return {
      cloudSpace,
      progressPercent,
      getPersonalCloudSpace
    }
  }
})
</script>

<style lang="scss" scoped>
.personal-cloud-space {
  position: fixed;
  bottom: 13px;
  left: 600px;
  width: 250px;
  height: 35px;
  line-height: 35px;
  margin-top: 10px;
  font-size: 14px;
  // background: #fff7ed;
  border-radius: 4px;
  // border: solid 1px #f3ae55;
  color: #000;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title{
    width:20%;
    overflow: hidden;
  }
  .progress-bar{
    position: relative;
    width: 80%;
    overflow: hidden;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(206, 206, 206);

    .progress-fill{
    position: absolute;
    left:0;
    top:0;
    height: 100%;
    transition: width 0.3s ease-in-out;
    border-radius: 10px;
    background-image: linear-gradient(to right, rgb(4, 134, 255), skyblue);

  }
  .percent-low {
    background-image: linear-gradient(to right, rgb(4, 134, 255), skyblue);
  }
  .percent-middle {
    background-image: linear-gradient(to right, #ff7800, #ffaa25);
  }
  .percent-full {
    background-image: linear-gradient(to right, #ea1f29, #ff6186);
  }
  .progress-text{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }
}
.popover-cloud-space{
  .popover-cloud-space-item{
    display: flex;
    width:100%;
    justify-content: space-between;
    .value{
      color: #f08801;
    }
  }
  .popover-cloud-space-item{
    width:100%;
  }
}
</style>

<style lang="scss">
.popover-cloud-space{
  .ant-popover-inner-content{
    width:100%;
  }
  .ant-popover-title{
    font-weight: bold;
  }
}
</style>
