import mitt, { Emitter } from 'mitt'

export const enum EmitterEvents {
  ADD_COURSEWARE_TASK = 'ADD_COURSEWARE_TASK',
  ON_COURSEWARE_TASK_PROGRESS = 'ON_COURSEWARE_TASK_PROGRESS',
  ON_TASK_PROGRESS = 'ON_TASK_PROGRESS',
  ON_TASK_CLICK = 'ON_TASK_CLICK'

}

export interface Job {
  id: string;
  name: string;
  type: 'uploadCourseware' | 'downloadCourseware';
  progress: number;
  status: 'pending' | 'start' | 'doing' | 'done' | 'failed';
  extra?: any;
}

type Events = {
  [EmitterEvents.ADD_COURSEWARE_TASK]: Job[];
  [EmitterEvents.ON_COURSEWARE_TASK_PROGRESS]: Job;
  [EmitterEvents.ON_TASK_PROGRESS]:Job
  [EmitterEvents.ON_TASK_CLICK]:Job

};

const emitter: Emitter<Events> = mitt<Events>()

export default emitter
