<template>
  <a-modal
    :visible="visibleCompu"
    title="创建课件"
    :maskClosable="false"
    :okButtonProps="{ loading: loadingCompu }"
    :cancelButtonProps="{ disabled: loadingCompu }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form ref="formRef" :model="formData">
      <a-form-item
        label="课件名"
        name="name"
        :rules="{ required: true, message: '请输入课件名' }"
      >
        <a-input v-model:value="formData.name" ref="inputRef"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, ref, onMounted, nextTick } from 'vue'
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['onOk', 'onCancel'],
  setup (props, { emit }) {
    const visibleCompu = computed(() => props.visible)

    const loadingCompu = computed(() => props.loading)

    const formRef = ref()
    const formData = ref({})

    const handleCancel = () => {
      emit('onCancel')
    }
    const inputRef = ref()

    const handleOk = () => {
      formRef.value.validate().then(() => {
        emit('onOk', formData.value)
      })
    }

    onMounted(async () => {
      await nextTick()
      inputRef.value.focus()
    })

    return {
      visibleCompu,
      loadingCompu,
      formRef,
      formData,
      handleCancel,
      handleOk,
      inputRef
    }
  }
}
</script>

<style>
</style>
