// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'workerize-loader?inline&ready&name=worker/mine-coursewares.[contenthash:8]!../worker'
import { onMounted, onBeforeUnmount, computed, createVNode } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router'
import emitter, { EmitterEvents } from '@/utils/common/emitter'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'
import * as service from '../service'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import _ from 'lodash'

/**
 * 主要处理我的课件的相关worker业务
 */
export default () => {
  const store = useStore()
  const token = computed(() => store.state.token)
  const baseURL = computed(() => store.state.baseURL)
  const apiPrefix = computed(() => store.state.config.apiPrefix)

  let worker: any = null
  /**
   * 初始化worker
   * @returns
   */
  const initWorker = () => {
    return new Promise((resolve, reject) => {
      if (!worker) {
        worker = new Worker()
        worker.ready
          .then(() => worker.createTaskController())
          .then(() => {
            worker.onmessage = onCoursewaresMessage
          })
          .then(() => {
            worker.initOssResource(
              baseURL.value + apiPrefix.value,
              token.value
            )
            worker.initOptions(process.env.APP_ENV || 'development', token.value)
          })
          .then(resolve(worker))
          .catch(reject)
        return
      }
      resolve(worker)
    })
  }

  /**
   * 销毁worker
   * @returns
   */
  const destroyWorker = () => {
    return new Promise((resolve, reject) => {
      if (worker) {
        worker
          .destoryTaskController()
          .then(() => worker.terminate())
          .then(() => (worker = null))
          .catch(e => reject(e))
      }
    })
  }

  /**
   * 添加任务
   * @param files
   */
  const addCourseWareTask = (task: any[] | null) => {
    return new Promise((resolve, reject) => {
      worker &&
        worker
          .addCourseWareTask(task)
          .then(result => {
            task = null
            // todo： 通知任务
            console.log(result)
            emitter.emit(EmitterEvents.ADD_COURSEWARE_TASK, result)

            resolve(result)
          })
          .catch(reject)
    })
  }

  const onCoursewaresMessage = (e: MessageEvent) => {
    const { type, result = {} } = e.data
    if (type === 'progress') {
      // todo: 通知进度
      emitter.emit(EmitterEvents.ON_COURSEWARE_TASK_PROGRESS, result)
      // if (result.progress === 100 && result.status === 'done') {
      //   result.type === 'uploadCourseware' ? createCourseware(result) : downloadCourseware(result)
      // }
    }
  }

  const createCourseware = (job: any) => {
    return new Promise<void>((resolve, reject) => {
      const { currentPath, extra = {} } = job
      const { paths } = extra
      const filepaths = _.cloneDeep(paths)
      filepaths.pop() // 最后一个是文件名称，去除
      const { name, url, size, md5, contentType, suffix } = extra
      getSpaceNodeIdforCreateCoursewareId(currentPath, filepaths)
        .then(async (spaceNode: any) => {
          const coursewareType = getCoursewareType(contentType, suffix)
          const params = {
            name,
            url,
            size,
            md5,
            type: coursewareType
          }
          const { id } = (await service.createTeacherCourseware(params)) as any
          mineCoursewareStatResport(
            STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_UPLOAD,
            {
              file_path: url,
              courseware_id: id,
              courseware_type: coursewareType
            }
          )
          return { coursewareId: id, spaceNodeId: spaceNode.id }
        })
        .then(async ({ coursewareId, spaceNodeId }) => {
          const nodeParams = {
            name,
            type: 1,
            parent_id: spaceNodeId,
            sort: 1,
            relation_id: coursewareId
          }
          await service.createSpaceNode(nodeParams).catch(reject)
          resolve()
        })
        .catch(reject)
    })
  }

  const downloadCourseware = (job: any) => {
    /**
     * 获取buffer等数据，调用下载，删除buffer对象
     */
    const { extra = {} } = job
    const { name, id, type, buffer } = extra
    const aLink = document.createElement('a')
    const blob = new Blob([buffer])
    const evt = document.createEvent('HTMLEvents')
    evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = name
    aLink.href = URL.createObjectURL(blob)
    aLink.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true
      })
    ) // 兼容火狐
    mineCoursewareStatResport(
      STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_DOWNLOAD,
      {
        file_path: name,
        courseware_id: id,
        courseware_type: type
      }
    )
  }

  // 递归查询，或者创建SpaceNode，获取最终的SpaceNodeId
  const getSpaceNodeIdforCreateCoursewareId = (
    currentPath: any,
    paths: string[]
  ) => {
    return new Promise((resolve, reject) => {
      if ((paths.length === 1 && !paths[0]) || paths.length === 0) {
        resolve(currentPath)
        return
      }
      //* 需要递归去创建和寻找文件夹id
      service
        .queryTeacherCoursewareList({
          parent_id: currentPath.id,
          type: 0,
          per_page: 1000
        })
        .then(async (res: any) => {
          const spaceNodes = res.items || []
          const spaceNodeName = paths[0]
          const spaceNode = spaceNodes.find(
            spaceNode => spaceNode.name === spaceNodeName
          )
          if (spaceNode) {
            paths.shift()
            const newCurrentPath = await getSpaceNodeIdforCreateCoursewareId(
              spaceNode,
              paths
            )
            resolve(newCurrentPath)
          } else {
            const { node_id } = (await service.createSpaceNode({
              name: spaceNodeName,
              type: 0,
              parent_id: currentPath.id,
              sort: 99
            })) as any
            paths.shift()
            const newCurrentPath = await getSpaceNodeIdforCreateCoursewareId(
              {
                id: node_id,
                parent_id: currentPath.id,
                name: spaceNodeName
              },
              paths
            )
            resolve(newCurrentPath)
          }
        })
        .catch(reject)
    })
  }

  // 获取课件类型
  const getCoursewareType = (contentType: string, suffix: string) => {
    const fileType = contentType.substring(0, contentType.lastIndexOf('/'))
    suffix = suffix.slice(suffix.lastIndexOf('.') + 1)
    if (
      contentType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      contentType === 'application/json'
    ) {
      return 'ppt'
    }
    return fileType === 'application' ? suffix : fileType
  }

  onBeforeRouteLeave((to, from, next) => {
    //! 得测试 是否完成了创建课件接口还没完成
    worker &&
      worker.getRunJobIds().then(jobIds => {
        if (jobIds.length > 0) {
          const modal = Modal.confirm({
            title: '警告',
            icon: createVNode(ExclamationCircleOutlined),
            content: '任务中心还有任务在进行中,跳转页面将导致任务取消',
            closable: true,
            onOk: () => {
              setTimeout(() => next(true), 0)
            },
            onCancel: () => {
              modal.destroy()
              next(false)
            }
          })
        } else {
          setTimeout(() => next(true), 0)
        }
      })
  })

  onMounted(() => {
    initWorker()
  })

  onBeforeUnmount(() => {
    destroyWorker()
  })

  return {
    addCourseWareTask,
    createCourseware,
    downloadCourseware,
    getSpaceNodeIdforCreateCoursewareId
  }
}
