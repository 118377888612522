<template>
  <a-layout>
    <a-layout-header class="head_body">
      <search-bar v-model:value="queryParams" @search="handleTopBarSearch">
        <template #suffix>
          <college-entry />
        </template>
      </search-bar>
      <div ref="reloadDiv"></div>
    </a-layout-header>
    <a-layout-content class="content_body">
      <a-card class="list_card_body">
        <template #title>
          <a-breadcrumb>
            <a-breadcrumb-item v-for="(path, index) in pathList" :key="path.id">
              <a @click="() => handleClickBreadcrumb(path.id, index)">{{
                pathList.length > 5 && index !== 0
                  ? index >= pathList.length - 2
                    ? path.name
                    : "..."
                  : path.name
              }}</a>
            </a-breadcrumb-item>
          </a-breadcrumb>
        </template>
        <template #extra>
          <a-space>
            <a-button
              v-if="!isSearch && currentPath.id !== 0"
              @click="handleGoBack"
              >返回上一级</a-button
            >
            <a-button
              v-if="!isSearch && teacherAuth"
              type="primary"
              @click="handleShowCreateFolderModal"
            >
              新建文件夹
            </a-button>
            <a-dropdown>
              <a-button type="primary" v-if="!isSearch && teacherAuth"
                >+新建</a-button
              >
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a-button
                      v-if="!isSearch"
                      type="text"
                      @click="handleShowCreatePptModal"
                    >
                      <fund-projection-screen-outlined style="color:#0099FF" />
                      新建课件
                    </a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button type="text" @click="handleCreatePlan">
                      <file-text-two-tone two-tone-color="#00CC33" />
                      新建教案
                    </a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button type="text" @click="handleShowUploadSpeak">
                      <user-outlined style="color:#FFCC00" />
                      新建说课
                    </a-button>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <a-popover
              v-model:visible="chooseFileModelVisible"
              placement="bottom"
              :trigger="['click']"
              overlayClassName="choose-files-modal-popover"
            >
              <a-button
                v-if="!isSearch && teacherAuth"
                type="primary"
                @click="handleCloseUpload"
              >
                上传文件
              </a-button>
              <template #content>
                <choose-files-modal
                  @change="handleChooseFileChange"
                ></choose-files-modal>
              </template>
            </a-popover>
            <ProtocolsModal
              v-model:checked="hasAccessProtocols"
              :update_time="hasAccessProtocolsUpdateTime"
              :protocol="Protocols.UploadServiceAgreement"
              :onlyDialog="true"
              @change="handleUploadProtocolChange"
              ref="uploadProtocolRef"
            ></ProtocolsModal>

            <a-button
              v-if="!isSearch && teacherAuth"
              @click="handleGoToShareList"
            >
              分享记录
            </a-button>
            <a-button v-if="isSearch" @click="handleGoBackToList"
              >返回我的课件</a-button
            >
          </a-space>
        </template>
        <a-spin :spinning="loading" size="large">
          <a-list
            :dataSource="coursewaresCompu"
            :pagination="pagination"
            :grid="gutterConfig"
          >
            <template #renderItem="{ item }">
              <a-list-item :key="`${item.id}-${item.updated_at}`">
                <folder
                  v-if="item.type === DirectoryItemType.FOLDER"
                  :value="item"
                  @onEnterFolder="handleEnterFolder"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  :look="teacherAuth"
                />
                <ppt-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.PPT
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  @onEdit="handleSavePageContext"
                  @onPreview="handleSavePageContext"
                  @exportPptx="handExportPptx"
                  :look="teacherAuth"
                  :checkPersonalCloudSpace="checkPersonalCloudSpace"
                  :checkCanShowEditByPlatform="checkCanShowEditByPlatform"
                />
                <audio-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.AUDIO
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  :look="teacherAuth"
                />
                <image-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.IMAGE
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  :look="teacherAuth"
                />
                <video-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.VIDEO
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  :look="teacherAuth"
                />
                <flash-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.FLASH
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  :look="teacherAuth"
                />
                <speak-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.SPEAK
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  @onEdit="handleEditSpeak"
                  :look="teacherAuth"
                />
                <plan-courseware
                  v-if="
                    item.type === DirectoryItemType.COURSEWARE &&
                      item.courseware.type === CoursewareType.Plan
                  "
                  :value="item"
                  @refresh="handleReloadList"
                  @showLoading="handleShowLoading"
                  @onMove="handleMoveNodeModal"
                  @onShare="handleClickToShare"
                  @onEdit="handleSavePageContext"
                  @onPreview="handleSavePageContext"
                  :data="currentPath"
                  :look="teacherAuth"
                  :checkPersonalCloudSpace="checkPersonalCloudSpace"
                />
              </a-list-item>
            </template>
          </a-list>
        </a-spin>
      </a-card>
      <create-folder-modal
        v-if="createFolderVisible"
        :visible="createFolderVisible"
        :loading="createFolderLoading"
        @onCancel="handleCancelCreateFolderModal"
        @onOk="handleCreateFolderOk"
      />
      <upload-file-modal
        v-if="uploadFileVisible"
        :visible="uploadFileVisible"
        :loading="uploadFileLoading"
        @onOk="handleUploadFileOk"
        @onCancel="handleCancelUploadFileModal"
      />
      <upload-speak
        v-if="uploadSpeakVisible"
        :visible="uploadSpeakVisible"
        :loading="uploadFileLoading"
        @onOk="handleUploadFileOk"
        @onCancel="handleCancelUploadFileModal"
        :lesson="createPptId"
        :speaking="editInfo"
      />
      <create-ppt-modal
        v-if="createPptVisible"
        :visible="createPptVisible"
        :loading="createPptLoading"
        @onCancel="handleCancelCreatePptModal"
        @onOk="handleCreatePptOk"
      />
      <move-node-modal
        v-if="moveNodeVisible"
        :visible="moveNodeVisible"
        :currentNode="currentMoveNode"
        @onOk="handleMoveNodeOk"
        @onCancel="handleCancelMoveNodeModal"
      />
      <share-courseware-modal
        v-if="shareModalVisible"
        :visible="shareModalVisible"
        :courseware="currentShareCourseware"
        @onCancel="handleShareCancel"
        @onOk="handleShareCancel"
      ></share-courseware-modal>
      <select-template-modal
        :visible="createPlanVisible"
        @Ok="handleSelectTemplateSection"
        :node="currentPath"
      />
    </a-layout-content>
    <transfer-progress class="transfer-progress"></transfer-progress>
    <personal-cloud-space ref="personalCloudSpaceRef"></personal-cloud-space>
  </a-layout>
</template>

<script>
import _ from 'lodash'
import SearchBar from '@/components/common/SearchBar.vue'
import { DirectoryItemType, CoursewareType } from './config'
import { computed, onMounted, onBeforeUnmount, ref, toRaw } from 'vue'
import * as service from './service'
import Folder from './components/Folder.vue'
import PptCourseware from './components/PptCourseware.vue'
import AudioCourseware from './components/AudioCourseware.vue'
import ImageCourseware from './components/ImageCourseware.vue'
import VideoCourseware from './components/VideoCourseware.vue'
import FlashCourseware from './components/FlashCourseware.vue'
import CreateFolderModal from './components/CreateFolderModal.vue'
import UploadFileModal from './components/UploadFileModal.vue'
import CreatePptModal from './components/CreatePptModal.vue'
import SpeakCourseware from './components/SpeakCourseware.vue'
import UploadSpeak from './components/UploadSpeak.vue'
import SelectTemplateModal from '@/views/courseware/components/SelectTemplateModal.vue'
import ShareCoursewareModal from './components/ShareCoursewareModal.vue'
import PlanCourseware from './components/PlanCourseware.vue'
import { message } from 'ant-design-vue'
import MoveNodeModal from './components/MoveNodeModal.vue'
import ChooseFilesModal from './components/ChooseFilesModal.vue'
import emitter, { EmitterEvents } from '@/utils/common/emitter'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'
import router from '@/router'
import config from '@/config'
import store from '@/store'
import {
  eventCenter,
  event,
  usePageTableContext
} from '@evideo/frontend-utils'
import CollegeEntry from '@/components/common/CollegeEntry.vue'
import PageKeyMap from '@/utils/pageTableContextMap'
import {
  FileTextTwoTone,
  UserOutlined,
  FundProjectionScreenOutlined
} from '@ant-design/icons-vue'

import useCoursewaresWorker from './hooks/useCoursewaresWorker.ts'
import TransferProgress from '@/views/mine-coursewares/components/TransferProgress.vue'
import PersonalCloudSpace from './components/PersonalCloudSpace.vue'
import { getCloudSpaceInfo } from './service'
import ProtocolsModal from './components/protocolsModal.vue'
import { Protocols } from '@/utils/dataMap/userProtocols'

export default {
  beforeRouteLeave(to, from) {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })
    console.log('preload end')
  },
  components: {
    SearchBar,
    Folder,
    PptCourseware,
    AudioCourseware,
    ImageCourseware,
    VideoCourseware,
    CreateFolderModal,
    UploadFileModal,
    CreatePptModal,
    MoveNodeModal,
    FlashCourseware,
    UploadSpeak,
    CollegeEntry,
    SpeakCourseware,
    ShareCoursewareModal,
    FileTextTwoTone,
    UserOutlined,
    FundProjectionScreenOutlined,
    SelectTemplateModal,
    PlanCourseware,
    ChooseFilesModal,
    TransferProgress,
    PersonalCloudSpace,
    ProtocolsModal
  },
  setup(props) {
    // 当前路径列表
    const pathList = ref([])

    // 课件信息列表
    const coursewaresList = ref([])

    const coursewaresCompu = computed(() => coursewaresList.value)

    // 关联ppt
    const createPptId = ref([])

    // 学校权限课件 没权限时只预览
    const teacherAuth = ref(false)

    // 搜索参数
    const queryParams = ref('')

    // 加载状态
    const loading = ref(false)

    // 当前路径节点
    const currentPath = ref({})

    // 是否是搜索模式
    const isSearch = ref(false)

    // 创建文件夹弹窗可见性
    const createFolderVisible = ref(false)
    // 创建文件夹加载状态
    const createFolderLoading = ref(false)
    // 上传文件弹窗可见性
    const uploadFileVisible = ref(false)
    // 上传文件弹窗加载状态
    const uploadFileLoading = ref(false)
    // 新建课件弹窗可见性
    const createPptVisible = ref(false)
    // 新建课件弹窗加载状态
    const createPptLoading = ref(false)

    // 新建说课弹窗可见
    const uploadSpeakVisible = ref(false)

    // 教案模板可见性
    const createPlanVisible = ref(false)

    // 上传、 编辑判断
    const editInfo = ref(true)

    // 移动节点的弹窗可见性
    const moveNodeVisible = ref(false)

    // 分享弹窗re
    const shareModalVisible = ref(false)
    const currentShareCourseware = ref()

    const currentMoveNode = ref({})

    const personalCloudSpaceRef = ref()

    const gutterConfig = {
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 3,
      xxl: 4
    }
    const hasAccessProtocols = ref(false)
    const hasAccessProtocolsUpdateTime = ref()
    const uploadProtocolRef = ref()

    const {
      getContextInfo,
      saveContextInfo,
      deleteContextInfo
    } = usePageTableContext()

    const {
      addCourseWareTask,
      createCourseware,
      downloadCourseware,
      getSpaceNodeIdforCreateCoursewareId
    } = useCoursewaresWorker()

    // 点击搜索按钮
    const handleTopBarSearch = () => {
      pagination.value.current = 1
      handleSearch()
    }

    // 教案模板选择
    const handleSelectTemplateSection = () => {
      handleSavePageContext()
      createPlanVisible.value = false
    }

    // 教案弹框
    const handleCreatePlan = () => {
      return new Promise((resolve, reject) => {
        checkPersonalCloudSpace()
          .then(res => {
            if (res) {
              createPlanVisible.value = true
            }
            resolve('')
          })
          .catch(err => {
            console.err('检查云空间容量失败')
            reject(err)
          })
      })
    }

    const handlePageChagne = value => {
      pagination.value.current = value
      if (isSearch.value === false) {
        handleChangePath(currentPath.value.id)
      } else {
        handleSearch()
      }
    }

    // 分页配置
    const pagination = ref({
      current: 1,
      total: 1,
      pageSize: 12,
      showTotal: total => `共${total}条`,
      showQuickJumper: true,
      hideOnSinglePage: true,
      onChange: handlePageChagne
    })

    // 获取指定parent_id下的数据
    const handleChangePath = async (id, params = '') => {
      personalCloudSpaceRef.value?.getPersonalCloudSpace &&
        personalCloudSpaceRef.value.getPersonalCloudSpace()
      const reqParams = {
        parent_id: id,
        page: pagination.value.current,
        per_page: pagination.value.pageSize
      }
      if (params.length > 0) {
        reqParams.name = params
      }
      try {
        loading.value = true
        const data = await service.queryTeacherCoursewareList(reqParams)
        const { items, meta } = data
        coursewaresList.value = items
        pagination.value = { ...toRaw(pagination.value), ...meta }
      } finally {
        loading.value = false
      }
    }

    // 面包屑增加一层
    const addPath = (id, name) => {
      const parentId = currentPath.value.id
      const newBre = {
        id,
        name,
        parent_id: parentId
      }
      pathList.value.push(newBre)
      currentPath.value = newBre
    }

    // 移除一层面包屑
    const removePath = () => {
      pathList.value.pop()
      const len = pathList.value.length
      const item = pathList.value[len - 1]
      currentPath.value = item
    }

    // 文件夹点击的时候
    const handleEnterFolder = (id, name) => {
      pagination.value.current = 1
      handleChangePath(id)
      addPath(id, name)
    }

    // 返回上一层
    const handleGoBack = () => {
      removePath()
      pagination.value.current = 1
      handleChangePath(currentPath.value.id)
    }

    // 点击面包屑跳转
    const handleClickBreadcrumb = (id, index) => {
      if (id === currentPath.value.id) {
        return
      }
      pagination.value.current = 1
      handleChangePath(id)
      const newPath = pathList.value.slice(0, index + 1)
      currentPath.value = newPath[index]
      pathList.value = newPath
    }

    // 展示创建文件夹弹窗
    const handleShowCreateFolderModal = () => {
      createFolderVisible.value = true
      console.log(coursewaresCompu)
    }

    // 隐藏创建文件夹弹窗
    const handleCancelCreateFolderModal = () => {
      createFolderVisible.value = false
    }

    // 创建文件夹
    const handleCreateFolderOk = async name => {
      const params = {
        name,
        type: 0,
        parent_id: currentPath.value.id,
        sort: 99
      }
      try {
        createFolderLoading.value = true
        await service.createSpaceNode(params)
        message.success('新建文件夹成功')
        handleChangePath(currentPath.value.id)
        createFolderLoading.value = false
        createFolderVisible.value = false
      } catch (error) {
        console.log(error)
        message.error('新建文件夹失败，请重试')
        createFolderLoading.value = false
      }
    }

    // 上传文件弹窗
    const handleShowUploadFileModal = () => {
      uploadFileVisible.value = true
      editInfo.value = true
    }

    // 说课文件弹窗
    const handleShowUploadSpeak = () => {
      return new Promise((resolve, reject) => {
        checkPersonalCloudSpace()
          .then(res => {
            if (res) {
              uploadSpeakVisible.value = true
              editInfo.value = true
            }
            resolve('')
          })
          .catch(err => {
            console.err('检查云空间容量失败')
            reject(err)
          })
      })
    }

    // 说课编辑弹窗
    const handleEditSpeak = data => {
      return new Promise((resolve, reject) => {
        checkPersonalCloudSpace()
          .then(res => {
            if (res) {
              uploadSpeakVisible.value = true
              editInfo.value = false
              createPptId.value = data.id
            }
            resolve('')
          })
          .catch(err => {
            console.err('检查云空间容量失败')
            reject(err)
          })
      })
    }

    // 隐藏上传文件弹窗
    const handleCancelUploadFileModal = () => {
      uploadFileVisible.value = false
      uploadSpeakVisible.value = false
      localStorage.removeItem('relate_coursewares')
    }

    // 上传文件选择完毕
    const handleUploadFileOk = async (data, info) => {
      console.log('上传文件', data)
      console.log('info', info)
      // 先创建课件，再创建节点
      const params = {
        name: data.name,
        plan_content: '',
        relate_courseware_ids: info,
        type: data.type,
        size: data.size,
        url: data.url,
        md5: data.md5,
        uuid: data.uuid
      }
      console.log(params)
      try {
        if (editInfo.value === true) {
          uploadFileLoading.value = true
          const { id } = await service.createTeacherCourseware(params)
          mineCoursewareStatResport(
            STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_CREATE,
            {
              file_path: params.url,
              courseware_id: id,
              courseware_type: params.type
            }
          )
          const nodeParams = {
            name: data.name,
            type: 1,
            parent_id: currentPath.value.id,
            sort: 1,
            relation_id: id
          }
          await service.createSpaceNode(nodeParams)
          message.success('保存成功')
          handleReloadList()
          uploadFileLoading.value = false
          uploadFileVisible.value = false
          uploadSpeakVisible.value = false
        } else {
          await service.editTeacherCourseware(data.id, params)
          mineCoursewareStatResport(
            STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_EDIT,
            {
              file_path: params.url,
              courseware_id: data.id,
              courseware_type: params.type
            }
          )
          message.success('保存成功')
          uploadSpeakVisible.value = false
          handleReloadList()
          editInfo.value = true
        }
        localStorage.removeItem('relate_coursewares')
      } catch (error) {
        console.log(error)
      }
    }

    const checkCanShowEditByPlatform = () => {
      return new Promise((resolve, reject) => {
        if (!window?.Electron || !window?.Electron?.Core?.getCoreOptions) {
          resolve(true)
          return
        }
        let currentPlatform = ''
        window.Electron.Core.getCoreOptions()
          .then(options => {
            const { arch, platform } = options || {}
            if (platform === 'darwin' || platform === 'safari') {
              currentPlatform = 'OS X'
            }
            if (
              platform === 'win32' ||
              platform === 'chrome' ||
              platform === 'ie' ||
              platform === 'firefox' ||
              platform === 'opera'
            ) {
              if (arch === 'x64' || arch === 'arm64') {
                currentPlatform = 'win64'
              } else {
                currentPlatform = 'win32'
              }
            }
            if (currentPlatform === 'win32') {
              resolve(false)
            }
            resolve(true)
          })
          .catch(err => reject(err))
      })
    }

    const handleShowCreatePptModal = async () => {
      return new Promise((resolve, reject) => {
        checkCanShowEditByPlatform()
          .then(res => {
            if (res) {
              checkPersonalCloudSpace()
                .then(res => {
                  if (res) {
                    createPptVisible.value = true
                  }
                  resolve('')
                })
                .catch(err => {
                  console.err('检查云空间容量失败')
                  reject(err)
                })
            } else {
              message.warning('win32电脑暂不支持编辑,请使用win64电脑')
            }
          })
          .catch(err => {
            console.err('获取当前系统信息失败：', err?.message)
            reject(err)
          })
      })
    }

    const handleSavePageContext = () => {
      const pageContext = {
        currentPath: toRaw(currentPath.value),
        pathList: toRaw(pathList.value),
        current_page: pagination.value.current_page,
        queryParams: toRaw(queryParams.value),
        isSearch: toRaw(isSearch.value)
      }
      saveContextInfo(PageKeyMap.MINE_COURSEWARE, pageContext)
    }

    // 新建课件
    const handleCreatePptOk = async data => {
      console.log(data)
      // 先创建课件，再创建节点
      const params = {
        name: data.name,
        type: CoursewareType.PPT,
        size: 0,
        url: '',
        md5: '',
        uuid: '',
        relate_courseware_ids: []
      }
      try {
        createPptLoading.value = true
        const { id } = await service.createTeacherCourseware(params)
        mineCoursewareStatResport(
          STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_CREATE,
          {
            courseware_id: id,
            courseware_type: CoursewareType.PPT
          }
        )
        const nodeParams = {
          name: data.name,
          type: 1,
          parent_id: currentPath.value.id,
          sort: 1,
          relation_id: id
        }
        await service.createSpaceNode(nodeParams)
        message.success('新建课件成功')
        handleSavePageContext()
        router.push(`/resource/mine-coursewares/${id}/edit`)
        createPptVisible.value = false
        createPptLoading.value = false
      } catch (error) {
        console.log(error)
        createPptLoading.value = false
      }
    }

    // 创建ppt的弹窗
    const handleCancelCreatePptModal = () => {
      createPptVisible.value = false
    }

    // 刷新当前列表
    const handleReloadList = async () => {
      if (!isSearch.value) {
        personalCloudSpaceRef.value?.getPersonalCloudSpace &&
          personalCloudSpaceRef.value.getPersonalCloudSpace()
        await handleChangePath(currentPath.value.id)
      } else {
        await handleSearch()
      }
    }

    // 展示移动弹窗
    const handleMoveNodeModal = nodeInfo => {
      currentMoveNode.value = nodeInfo
      moveNodeVisible.value = true
    }

    // 取消移动弹窗
    const handleCancelMoveNodeModal = () => {
      moveNodeVisible.value = false
    }

    // 移动到功能选择结束
    const handleMoveNodeOk = async id => {
      const params = {
        name: currentMoveNode.value.name,
        type: currentMoveNode.value.type,
        parent_id: id,
        relation_id: currentMoveNode.value.relation_id,
        sort: currentMoveNode.value.sort
      }
      try {
        loading.value = true
        await service.editSpaceNode(currentMoveNode.value.id, params)
        message.success('移动成功')
        loading.value = false
        await handleReloadList()
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    // 更改loading
    const handleShowLoading = value => {
      loading.value = value
    }

    // 检索
    const handleSearch = async () => {
      personalCloudSpaceRef.value?.getPersonalCloudSpace &&
        personalCloudSpaceRef.value.getPersonalCloudSpace()
      try {
        loading.value = true
        const searchPath = {
          id: -1,
          name: '检索结果',
          parent_id: 0
        }
        pathList.value = [searchPath]
        currentPath.value = searchPath
        const reqParams = {
          name: queryParams.value,
          page: pagination.value.current,
          per_page: pagination.value.pageSize,
          type: 1
        }
        isSearch.value = true
        const { items, meta } = await service.queryTeacherCoursewareList(
          reqParams
        )
        const list = items.map(item => {
          return {
            name: item.name,
            id: item?.id,
            type: item?.type,
            parent_id: item?.parent_id,
            updated_at: item?.updated_at,
            courseware: {
              name: item.name,
              id: item.courseware.id,
              url: item.courseware.url,
              size: item.courseware.id,
              updated_at: item.updated_at,
              type: item.courseware.type,
              share_mode: item.courseware.share_mode
            }
          }
        })
        coursewaresList.value = list
        pagination.value = {
          ...toRaw(pagination.value),
          ...meta,
          current: meta.current_page
        }
        console.log('分页 ====》 ', pagination.value)
        loading.value = false
      } catch (error) {
        console.error(error)
        coursewaresList.value = []
        loading.value = false
      }
    }

    // 初始化面包屑
    const initBreadcrumb = () => {
      const rootPath = {
        id: 0,
        name: '我的课件',
        parent_id: 0
      }
      currentPath.value = rootPath
      pathList.value = [rootPath]
    }

    // 退出检索
    const handleGoBackToList = () => {
      isSearch.value = false
      queryParams.value = ''
      initBreadcrumb()
      handleChangePath(0)
    }

    const reloadDiv = ref()
    const reloadApp = async () => {
      const divElm = document.createElement('div')
      divElm.style = 'display: none'
      reloadDiv.value.appendChild(divElm)
      const preloadPromise = new Promise((resolve, reject) => {
        try {
          const pptEntryUrl = config.pptist.entry
          eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
            app: config.pptist.name,
            el: divElm,
            entry: pptEntryUrl,
            args: {
              token: store.state.token,
              microAppUrl: pptEntryUrl,
              appName: config.pptist.name,
              preload: true
            },
            configuration: { $$cacheLifecycleByAppName: true } // TODO: $$cacheLifecycleByAppName是qiankun的一个内部实验性开关
          })
          eventCenter.sub(`${config.pptist.name}-preloadOk`, () => {
            console.log('preload is ok , unload the app!')
            eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
              app: config.pptist.name
            })
            store.commit('setHasPreloadPPT', true)
            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })

      await preloadPromise
    }

    // 分享弹窗点击取消
    const handleShareCancel = () => {
      shareModalVisible.value = false
    }

    // 选择分享课件
    const handleClickToShare = async courseware => {
      if (courseware.courseware.type === 'plan') {
        const data = await service.getTeacherCourseware(
          courseware.courseware.id
        )
        if (data.plan_content === '<p><br></p>') {
          message.error('当前课件内容为空，请先保存内容后再进行分享')
          return
        }
      } else {
        if (canNotShare(courseware)) {
          message.error('当前课件内容为空，请先保存内容后再进行分享')
          return
        }
      }
      currentShareCourseware.value = courseware
      shareModalVisible.value = true
    }

    const handleGoToShareList = () => {
      handleSavePageContext()
      router.push('/resource/mine-coursewares/share-list')
    }

    // 课件对应oss地址为空的时候不允许分享
    const canNotShare = courseware => {
      return courseware.courseware.url.length < 1
    }

    const handleInitparamsByPageContext = () => {
      console.log(999)
      const context = getContextInfo(PageKeyMap.MINE_COURSEWARE)
      if (context.currentPath) {
        currentPath.value = context.currentPath
      }
      if (context.pathList) {
        pathList.value = context.pathList
      }
      if (context.current_page) {
        pagination.value.current = context.current_page
      }
      if (context.queryParams) {
        queryParams.value = context.queryParams
      }
      if (context.isSearch) {
        isSearch.value = context.isSearch
      }
      deleteContextInfo(PageKeyMap.MINE_COURSEWARE)
    }

    // new 上传文件新逻辑

    const chooseFileModelVisible = ref(false)

    const handleChooseFileChange = files => {
      chooseFileModelVisible.value = false
      const task = {
        type: 'uploadCourseware',
        currentPath: toRaw(currentPath.value),
        extra: files
      }
      const paths = files.reduce((paths, current) => {
        if (paths.length > current.paths.length) {
          return paths
        }
        return current.paths
      }, [])
      const filepaths = _.cloneDeep(paths)
      filepaths.pop()
      getSpaceNodeIdforCreateCoursewareId(
        toRaw(currentPath.value),
        filepaths
      ).then(() => addCourseWareTask(task))
      // await 创建文件夹
    }
    // 导出pptx业务
    const handExportPptx = fileInfo => {
      const task = {
        type: 'downloadCourseware',
        currentPath: toRaw(currentPath.value),
        extra: {
          id: fileInfo.id,
          type: fileInfo.type,
          outputName: `${fileInfo.name}.pptx`,
          name: fileInfo.name,
          jsonUrl: fileInfo.url
        }
      }
      addCourseWareTask(task)
    }

    const onTaskProcessOK = job => {
      if (job.status === 'done' && job.type === 'uploadCourseware') {
        createCourseware(job).then(() => {
          handleReloadList()
          message.success(`${job.name}上传成功，课件已更新`)
        })
      } else if (job.status === 'done' && job.type === 'downloadCourseware') {
        message.success(`${job.name}已导出，请至左下角任务中心下载`)
      } else if (job.status === 'failed') {
        if (job?.extra?.errorType === 'CLOUD_SPACE_OVER') {
          // message.error(`${job.name}任务失败，云空间不足,请删除不必要的资源`)
        } else {
          message.error(
            `${job.name}任务失败，请重新尝试，若是还不行，请反馈相关人员`
          )
        }
      }
    }

    const onTaskProcessClick = job => {
      if (job.status === 'done' && job.type === 'downloadCourseware') {
        downloadCourseware(job)
      }
    }

    const checkPersonalCloudSpace = () => {
      return new Promise((resolve, reject) => {
        getCloudSpaceInfo()
          .then(res => {
            if (res && res?.space_limit - res?.total_size > 0) {
              resolve(true)
              return
            }
            message.error('云空间不足,请删除不必要的资源')
            resolve(false)
          })
          .catch(err => {
            console.error('获取个人云空间信息失败：', err?.message)
            reject(err)
          })
      })
    }
    const handleUploadProtocolChange = protocol => {
      const currentUser = store.state.currentUser
      const teacherId = currentUser.id
      localStorage.setItem(
        `uploadProtocol_${teacherId}`,
        JSON.stringify(protocol)
      )
      chooseFileModelVisible.value = protocol.checked
    }
    const initUploadProtocol = () => {
      const currentUser = store.state.currentUser
      const teacherId = currentUser.id
      const localProtocol = JSON.parse(
        localStorage.getItem(`uploadProtocol_${teacherId}`) ||
          '{ "checked": false }'
      )
      hasAccessProtocols.value = localProtocol.checked
      hasAccessProtocolsUpdateTime.value = localProtocol.update_time
    }
    const handleCloseUpload = e => {
      console.log(11111)
      initUploadProtocol()
      if (!hasAccessProtocols.value) {
        e.preventDefault()
        setTimeout(() => {
          chooseFileModelVisible.value = false
        }, 0)
        uploadProtocolRef.value.showProtocolModal()
      }
    }

    onMounted(async () => {
      initUploadProtocol()
      initBreadcrumb()
      handleInitparamsByPageContext()
      const hasPreload = store.state.hasPreloadPPT
      const data = store.state.permissions
      console.log('权限', data)
      if (data.length > 3) {
        teacherAuth.value = true
      }
      if (hasPreload === false) {
        reloadApp()
      }
      // 从上下文获取到上一次是空间的 页面离开的
      if (currentPath.value.id > -1) {
        await handleChangePath(currentPath.value.id)
      } else {
        // 搜索页离开的
        await handleSearch()
      }
      emitter.on(EmitterEvents.ON_TASK_PROGRESS, onTaskProcessOK)
      emitter.on(EmitterEvents.ON_TASK_CLICK, onTaskProcessClick)
    })

    onBeforeUnmount(() => {
      emitter.off(EmitterEvents.ON_TASK_PROGRESS, onTaskProcessOK)
      emitter.off(EmitterEvents.ON_TASK_CLICK, onTaskProcessClick)
    })

    return {
      reloadDiv,
      pathList,
      coursewaresList,
      coursewaresCompu,
      pagination,
      queryParams,
      DirectoryItemType,
      CoursewareType,
      gutterConfig,
      loading,
      currentPath,
      createFolderVisible,
      createFolderLoading,
      uploadFileVisible,
      uploadFileLoading,
      createPptVisible,
      createPptLoading,
      moveNodeVisible,
      currentMoveNode,
      isSearch,
      currentShareCourseware,
      shareModalVisible,
      uploadSpeakVisible,
      personalCloudSpaceRef,
      handleChangePath,
      handleEnterFolder,
      handleGoBack,
      handleClickBreadcrumb,
      handleShowCreateFolderModal,
      handleCancelCreateFolderModal,
      handleCreateFolderOk,
      handleShowUploadFileModal,
      handleCancelUploadFileModal,
      handleUploadFileOk,
      handleCreatePptOk,
      handleShowCreatePptModal,
      handleCancelCreatePptModal,
      handleReloadList,
      handleShowLoading,
      handleMoveNodeModal,
      handleMoveNodeOk,
      handleCancelMoveNodeModal,
      handleSearch,
      handleGoBackToList,
      handleShareCancel,
      handleClickToShare,
      handleGoToShareList,
      handleSavePageContext,
      handleTopBarSearch,
      handleShowUploadSpeak,
      handleSelectTemplateSection,
      createPlanVisible,
      handleCreatePlan,
      handleEditSpeak,
      createPptId,
      editInfo,
      teacherAuth,
      chooseFileModelVisible,
      handleChooseFileChange,
      handExportPptx,
      checkPersonalCloudSpace,
      Protocols,
      hasAccessProtocols,
      hasAccessProtocolsUpdateTime,
      uploadProtocolRef,
      handleUploadProtocolChange,
      handleCloseUpload,
      checkCanShowEditByPlatform
    }
  }
}
</script>

<style>
.head_body {
  background-color: #fff;
  height: 86px;
  padding-top: 8px;
}

.content_body {
  margin: 30px 30px;
}

.list_card_body {
  height: 100%;
}

.transfer-progress {
  position: fixed;
  bottom: 54px;
  left: 226px;
}
</style>
<style lang="scss">
.choose-files-modal-popover {
  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-inner-content {
        width: 347px !important;
        padding: 0 !important;
      }
    }
  }
}
</style>
